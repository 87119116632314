html, body {
    height: 100%;
}

#root{
  height: 100%;
}

.w-100{
  width: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

.m-bot-1 {
  margin-bottom: 1rem;
}

.nav-active {
  cursor: default !important;
  color: white !important;
}
